import React from "react"
import loadable from "@loadable/component";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Button, HeaderBG, SassyText, Section, SectionWrapper, SubTitle, Text, Title } from "../components/core";
import { OnDesktop, OnMobile } from "../components/DesktopMobileComps";
import { MetaComponent } from "../components";
import { useMixpanel } from "../context/MixpanelContext";

import Bullet from "../assets/images/green_check.svg"
import useHindiLangFont from "../hooks/useHindiLangFont";
import { LeadFormProvider } from "../context/LeadFormContext";

const Footer = loadable(() => import("../components"), {
    resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../components/LeadForm"));
const Navbar = loadable(() => import("../components"), {
    resolveComponent: (components) => components.Navbar,
});
const Testimonials = loadable(() => import("../components/Testimonials"))
const VideoSection = loadable(() => import("../components/VideoSection"));

export const OurCustomers = () => {
    const {t} = useTranslation(['our-customers', 'testimonials', 'common'])
    const { hindiBold } = useHindiLangFont()
    const mixpanel = useMixpanel()
    const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

    const handleQuoteModalOpen = () => {
        setIsQuoteModalOpen(true);
    };

    const handleQuoteModalClose = () => {
        setIsQuoteModalOpen(false);
    };

    const handleGetQuoteClick = (e, mxEventName = "") => {
        if (mxEventName) {mixpanel.track(mxEventName)}
        handleQuoteModalOpen()
    }

    return (
        <> 
            <LeadFormProvider>
                {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
            </LeadFormProvider>
            <MetaComponent
                title="Our Customers - Verak"
                description="Our customers operate a variety of businesses across India including shops, restaurants, godowns, factories, and kirana stores. Here's what they have to say about Verak!"
                url="aboutus"
            />
            <Navbar active="aboutus" />
            <Section background="#ffffff" style={{marginTop: "80px"}}>
                <OnDesktop><HeaderBG fullBg imgStyle={{height: "570px"}} /></OnDesktop>
                <OnMobile><HeaderBG fullBg imgStyle={{height: "800px"}} /></OnMobile>
                <SectionWrapper>
                    <Title fontSize="58px" mfontSize="32px" lineHeight="70px" mlineHeight="38px" style={{textAlign: "left"}} className={hindiBold}>
                        <SassyText>{t('Title')}</SassyText>
                    </Title>
                    <OurCustomersSubHeader>
                        <CustomersGraphicContainer>
                            <StaticImage 
                                src="../assets/images/customers.webp"
                                alt="our customers"
                                width={336}
                                height={224}
                                loading="lazy"
                            />
                        </CustomersGraphicContainer>
                        <Text fontSize="30px" mfontSize="18px" fontWeight="bold" lineHeight="51px" mlineHeight="30.6px" style={{textAlign: "left"}} className={hindiBold}>
                            {t('Subtitle')}
                        </Text>
                    </OurCustomersSubHeader>
                </SectionWrapper>
            </Section>
            <VideoSection videoLink="https://www.youtube.com/embed/3-W8WgPMV1w" wrapperStyle={{padding: "1.75rem 0", paddingBottom: "0rem"}} />
            <IssuesSolvedSection>
                <SectionWrapper>
                    <SubTitle fontSize="36px" lineHeight="61px" mfontSize="24px" mlineHeight="41px" className={hindiBold} style={{textAlign: "left"}}>
                        <SassyText>{t('IssuesSolved.Title')}</SassyText>
                    </SubTitle>
                    <ListContainer>
                        <ListItem>
                            <Text fontSize="20px" lineHeight="34px" mfontSize="14px" fontWeight="bold" className={hindiBold}>
                                <Bullet className="bullet" />
                                {t('IssuesSolved.Awareness')}
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="20px" lineHeight="34px" mfontSize="14px" fontWeight="bold" className={hindiBold}>
                                <Bullet className="bullet" />
                                {t('IssuesSolved.FollowUps')}
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="20px" lineHeight="34px" mfontSize="14px" fontWeight="bold" className={hindiBold}>
                                <Bullet className="bullet" />
                                {t('IssuesSolved.ClaimsSupport')}
                            </Text>
                        </ListItem>
                    </ListContainer>
                </SectionWrapper>
            </IssuesSolvedSection>
            {/* TODO: add testimonials here */}
            <Section background="#fff">
                <SectionWrapper>
                    <SubTitle fontSize="36px" lineHeight="61px" mfontSize="24px" mlineHeight="41px" className={hindiBold} style={{marginBottom: "2.5rem", textAlign: "left"}}>
                        <SassyText>{t('TestimonialsTitle')}</SassyText>
                    </SubTitle>
                    <Testimonials />
                </SectionWrapper>
            </Section>
            <Section background="#FFF7F1" style={{marginTop: "24px"}}>
                <SectionWrapper>
                    <ClosingSectionContainer>
                        <div style={{width: "100%"}}>
                            <SubTitle fontWeight="bold" fontSize="48px" mfontSize="24px" lineHeight="57px" mlineHeight="28.8px" style={{textAlign: "left"}} className={hindiBold}>
                                <SassyText>{t('JoinOurCustomers')}</SassyText>
                            </SubTitle>
                            <OnDesktop><Button wide primary label={t('CTALabels.GetAQuote')} onClick={handleGetQuoteClick} style={{marginTop: "2rem"}} /></OnDesktop>
                        </div>
                        <CustomersGraphicContainer>
                            <StaticImage 
                                src="../assets/images/customers.webp"
                                alt="our customers"
                                width={336}
                                height={224}
                                loading="lazy"
                            />
                        </CustomersGraphicContainer>
                    </ClosingSectionContainer>
                    <OnMobile><Button wide primary label={t('CTALabels.GetAQuote')} onClick={handleGetQuoteClick} style={{margin: "auto"}} /></OnMobile>
                </SectionWrapper>
            </Section>
            <Footer />
        </>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["our-customers", "testimonials", "common", "navbar", "footer", "getQuoteModal"]
        }, 
        language: {eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CustomersGraphicContainer = styled.div`
    width: min(336px, 100%);
    min-width: 250px;
    @media screen and (max-width: 768px) {
        min-width: 100px;
        width: min(150px, 100%);
    }
`

const OurCustomersSubHeader = styled.div`
    display: flex; 
    justify-content: space-between; 
    margin-top: 48px; 
    gap: 80px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 32px;
        margin-bottom: 100px;
    }
`

const IssuesSolvedSection = styled(Section)`
    margin-top: 100px;
    background: #fff;
    @media screen and (max-width: 768px) {
        margin-top: 60px;
    }
`

const ListContainer = styled.ul`
  margin: 36px 0px;
`;

const ListItem = styled.li`
    margin-left: 24px;
    margin-bottom: 24px;
    width: fit-content;
    color: #999999;
    list-style: none;
    text-align: left;
    .bullet {
        margin-left: -20px;
        margin-right: 35px;
        vertical-align: middle;
    }
    @media screen and (max-width: 768px) {
        .bullet {
            margin-right: 12px;
        }
    }
`

const ClosingSectionContainer = styled(OurCustomersSubHeader)`
    gap: 46px;
    margin-top: 75px;
    @media screen and (max-width: 768px) {
        margin-top: 60px;
        margin-bottom: 36px;
    }
`

export default OurCustomers